<template>
    <v-container>
        <v-row justify="center">
            <!-- Programs slider -->
            <v-col cols="12" :lg="actualities.length > 0 ? 8 : 12">
                <v-card>
                    <v-carousel hide-delimiters height="500">
                        <template v-slot:prev="{ on }">
                            <v-btn icon v-on="on">
                                <v-icon> fas fa-angle-left </v-icon>
                            </v-btn>
                        </template>

                        <template v-slot:next="{ on }">
                            <v-btn icon v-on="on">
                                <v-icon> fas fa-angle-right </v-icon>
                            </v-btn>
                        </template>

                        <v-carousel-item v-for="program in programs" :key="program.id" :src="getProgramPhoto(program)">
                            <v-hover v-slot="{ hover }">
                                <v-row class="fill-height ma-0">
                                    <v-col cols="12 pa-0">
                                        <router-link :to="`/programmes/${program.id}`" class="no-text-decoration">
                                            <v-sheet class="pa-4" :color="program.translation[getConfig('programs.translation_promote_code', 'promo_1')] ? 'rgba(255, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.5)'">
                                                <v-row align="center">
                                                    <v-col cols="2">

                                                    </v-col>

                                                    <v-col cols="8">
                                                        <h2 class="text-center white--text">
                                                            {{ program.title }} - {{ program.city }}
                                                        </h2>

                                                        <template v-if="program.translation[getConfig('programs.translation_promote_code', 'promo_1')]">
                                                            <br>
                                                            <div class="text-center" style="max-height: 50px; overflow-y: hidden; " v-html="program.translation[getConfig('programs.translation_promote_code', 'promo_1')]">
                                                            </div>
                                                        </template>

                                                    </v-col>

                                                    <v-col cols="2" class="text-right">
                                                        <v-fade-transition>
                                                            <v-btn v-show="hover" small :to="`/programmes/${program.id}`"> Plus d'info</v-btn>
                                                        </v-fade-transition>
                                                    </v-col>
                                                </v-row>
                                            </v-sheet>
                                        </router-link>
                                    </v-col>

                                    <v-col cols="12" align-self="end">
                                        <v-row justify="center">
                                            <v-col cols="11" xl="9">
                                                <v-sheet class="pa-4" color="rgba(0, 0, 0, 0.5)">
                                                    <v-row>
                                                        <v-col class="pa-1">
                                                            <ProgramLocationSelect v-model="productSearch.locations" :places="places" placeholder="Localisation" />
                                                        </v-col>
                                                    </v-row>

                                                    <v-row>
                                                        <v-col class="pa-1">
                                                            <ProductTypeSelect v-model="productSearch.types" placeholder="Natures" multiple />
                                                        </v-col>

                                                        <v-col class="pa-1">
                                                            <ProductRoomsSelect v-model="productSearch.rooms" placeholder="Typologies" multiple />
                                                        </v-col>

                                                        <v-col class="pa-1">
                                                            <ProgramFiscaliteSelect v-model="productSearch.fiscalites" placeholder="Fiscalités" multiple />
                                                        </v-col>

                                                        <v-col class="pa-1">
                                                            <v-text-field v-model="productSearch.budgetMax" placeholder="Budget max" dense hide-details solo light />
                                                        </v-col>
                                                    </v-row>

                                                    <v-row>
                                                        <v-col class="white--text pa-1">
                                                            <span>
                                                                {{ productsCount }} lots sur {{ programsCount }} programmes
                                                            </span>
                                                        </v-col>

                                                        <v-col class="text-right pa-1">
                                                            <v-btn @click="gotoProductsSearch" small> Rechercher </v-btn>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row>
                                                        <v-col class="pa-1">
                                                            <ProgramAutocomplete ref="programAutocomplete" v-model="programQuickAccess" placeholder="Programme" noDynamicSearch hideArchived :initPrograms="allPrograms">
                                                                <template v-slot:prepend>
                                                                    <span class="white--text pt-1" style="white-space: nowrap"> Accès rapide </span>
                                                                </template>
                                                            </ProgramAutocomplete>
                                                        </v-col>
                                                    </v-row>
                                                </v-sheet>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-hover>
                        </v-carousel-item>
                    </v-carousel>
                </v-card>
            </v-col>

            <!-- Actualités -->
            <v-col v-if="actualities.length > 0" cols="12" lg="4">
                <v-card @mouseenter="onActualityEnter" @mouseleave="onActualityLeave" class="d-flex flex-column fill-height" style="max-height: 500px" outlined>
                    <v-card-title class="primary--text title py-0 mb-0">
                        Actualités
                    </v-card-title>

                    <v-divider />

                    <template v-if="actualities.length > 0">
                        <v-window v-model="actualitiesPage" class="fill-height">
                            <v-window-item v-for="actuality of actualities" :key="actuality.id" class="fill-height">
                                <ActualityCard :initActuality="actuality" imageHeight="200px" />
                            </v-window-item>
                        </v-window>

                        <v-card-actions v-if="actualities.length > 1" class="justify-space-between flex-grow-1 align-end">
                            <v-btn icon @click="prev()">
                                <v-icon> fas fa-angle-left </v-icon>
                            </v-btn>

                            <v-item-group v-model="actualitiesPage" class="text-center" mandatory>
                                <v-item v-for="n in actualities" :key="n.keyid" v-slot="{ active, toggle }">
                                    <v-btn :input-value="active" icon @click="toggle">
                                        <v-icon small> fas fa-circle </v-icon>
                                    </v-btn>
                                </v-item>
                            </v-item-group>

                            <v-btn icon @click="next()">
                                <v-icon> fas fa-angle-right </v-icon>
                            </v-btn>
                        </v-card-actions>
                    </template>

                    <template v-else>
                        <v-row class="fill-height" align="center">
                            <v-col class="text-center">
                                <h3> Aucune actualité </h3>
                            </v-col>
                        </v-row>
                    </template>
                </v-card>
            </v-col>
        </v-row>

        <v-row justify="space-around">
            <!-- Mes derniers contacts -->
            <v-col cols="12" lg="4">
                <v-card class="axessia-card d-flex flex-column fill-height" outlined>
                    <v-toolbar class="flex-grow-0 mb-0 ma-2" dense flat>
                        <v-toolbar-title class="primary--text title">
                            Mes derniers contacts
                        </v-toolbar-title>

                        <v-spacer />

                        <v-fade-transition>
                            <v-text-field ref="contactsSearch" v-show="displayContactsSearch" v-model="contactsSearch" label="Rechercher" @keyup.enter="gotoContactsSearch()" @blur="closeContactSearch()" style="max-width: 200px" class="mb-1" dense hide-details />
                        </v-fade-transition>

                        <v-btn @click="toggleContactsSearch()" class="mr-2" icon small>
                            <v-icon small> fas fa-search </v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-text class="py-0">
                        <v-list v-if="contacts.length > 0" class="py-0">
                            <template v-for="contact of contacts">
                                <v-list-item :key="contact.id  + '- '+ contact.contactPartnerId" :to="`/contacts/${contact.id}`">
                                    <v-list-item-avatar class="dashboard-contact white--text text-uppercase headline">
                                        {{ contact.name ? contact.name.slice(0, 1) : '' }}
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ contact.name }} {{ contact.firstname }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <span v-if="contact.email">
                                                {{ contact.email }}
                                            </span>
                                            <span v-else>
                                                <small>
                                                    <i> Pas d'email </i>
                                                </small>
                                            </span>

                                            -

                                            <span v-if="contact.mobile">
                                                {{ contact.mobile }}
                                            </span>
                                            <span v-else>
                                                <small>
                                                    <i> Pas de téléphone </i>
                                                </small>
                                            </span>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider :key="contact.id  + '- '+ contact.contactPartnerId + '-divider'" />
                            </template>
                        </v-list>

                        <h2 v-if="contacts.length === 0" class="text-center grey--text text--lighten-1">
                            Aucun contact
                        </h2>
                    </v-card-text>

                    <v-card-actions class="align-end flex-grow-1">
                        <v-btn v-if="contacts.length > 0" to="/contacts" class="mt-4" block small depressed>
                            Voir plus
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <!-- Mes dernières options -->
            <v-col cols="12" lg="4">
                <v-card class="axessia-card d-flex flex-column fill-height" outlined>
                    <v-toolbar class="flex-grow-0 mb-0 ma-2" dense flat>
                        <v-toolbar-title class="primary--text title">
                            Mes dernières options
                        </v-toolbar-title>

                        <v-spacer />

                        <v-fade-transition>
                            <v-text-field ref="optionsSearch" v-show="displayOptionsSearch" v-model="optionSearch" label="Rechercher" @keyup.enter="gotoOptionsSearch()" @blur="closeOptionsSearch()" style="max-width: 200px" class="mb-1" dense hide-details />
                        </v-fade-transition>

                        <v-btn @click="toggleOptionsSearch()" class="mr-2" icon small>
                            <v-icon small> fas fa-search </v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-text class="py-0">
                        <v-list v-if="options.length > 0" class="py-0">
                            <template v-for="option of options">
                                <v-list-item :key="option.id">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-chip :color="getOptionStatus(option.optionstatus, 'color')" class="white--text mr-2" label small>
                                                {{ getOptionStatus(option.optionstatus, 'label') }}
                                                <v-icon right> {{ getOptionStatus(option.optionstatus, 'icon') }} </v-icon>
                                            </v-chip>

                                            <v-chip color="contact-background lighten-4" small label :to="`/contacts/${option.contact.id}`">
                                                {{ option.contact.name }} {{ option.contact.firstname }}
                                            </v-chip>
                                        </v-list-item-title>

                                        <v-list-item-subtitle>
                                            {{ option.product.reference }}
                                            {{ option.product.typeLabel }}
                                            {{ option.product.roomsLabel }}

                                            -
                                            {{ option.program.title }} -
                                            {{ option.program.city }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider :key="option.id + '-divider'" />
                            </template>
                        </v-list>

                        <h2 v-if="options.length === 0" class="text-center grey--text text--lighten-1">
                            Aucune option
                        </h2>
                    </v-card-text>

                    <v-card-actions class="align-end flex-grow-1">
                        <v-btn v-if="options.length > 0" to="/options" class="mt-4" block small depressed>
                            Voir plus
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <!-- Mes dernières réservations -->
            <v-col cols="12" lg="4">
                <v-card class="axessia-card d-flex flex-column fill-height" outlined>
                    <v-toolbar class="flex-grow-0 mb-0 ma-2" dense flat>
                        <v-toolbar-title class="primary--text title">
                            Mes dernières réservations
                        </v-toolbar-title>

                        <v-spacer />

                        <v-fade-transition>
                            <v-text-field ref="salesSearch" v-show="displaySalesSearch" v-model="saleSearch" label="Rechercher" @keyup.enter="gotoSalesSearch()" @blur="closeSalesSearch()" style="max-width: 200px" class="mb-1" dense hide-details />
                        </v-fade-transition>

                        <v-btn @click="toggleSalesSearch()" class="mr-2" icon small>
                            <v-icon small> fas fa-search </v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-text class="py-0">
                        <v-list v-if="sales.length > 0" class="py-0">
                            <template v-for="sale of sales">
                                <v-list-item :to="getConfig('sales.enabled', false) ? `/reservations/${sale.id}` : ''" :key="sale.id">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-chip :color="getSaleStatus(sale.status, 'color')" class="white--text mr-2" label small>
                                                {{ getSaleStatus(sale.status, 'label') }}
                                            </v-chip>

                                            <v-chip color="contact-background lighten-4" small label>
                                                {{ sale.contact.name }} {{ sale.contact.firstname }}
                                            </v-chip>
                                        </v-list-item-title>

                                        <v-list-item-subtitle>
                                            {{ sale.product.reference }}
                                            {{ sale.product.typeLabel }}
                                            {{ sale.product.roomsLabel }}
                                            -
                                            {{ sale.program.title }} -
                                            {{ sale.program.city }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider :key="sale.id + '-divider'" />
                            </template>
                        </v-list>

                        <h2 v-if="sales.length === 0" class="text-center grey--text text--lighten-1">
                            Aucune réservation
                        </h2>
                    </v-card-text>

                    <v-card-actions class="align-end  pt-0">
                        <v-btn v-if="sales.length > 0" to="/reservations" class="mt-4" block small depressed>
                            Voir plus
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import salesMixin from '../mixins/sales.js';
import optionsMixin from '../mixins/options.js';
import ActualityCard from '../components/actualities/ActualityCard.vue';
import ProductTypeSelect from '../components/widgets/ProductTypeSelect.vue';
import ProductRoomsSelect from '../components/widgets/ProductRoomsSelect.vue';
import ProgramAutocomplete from '../components/widgets/ProgramAutocomplete.vue';
import ProgramLocationSelect from '../components/widgets/ProgramLocationSelect.vue';
import ProgramFiscaliteSelect from '../components/widgets/ProgramFiscaliteSelect.vue';

export default {
    name: 'Dashboard',

    mixins: [salesMixin, optionsMixin],

    components: {
        ActualityCard,
        ProductTypeSelect,
        ProductRoomsSelect,
        ProgramAutocomplete,
        ProgramLocationSelect,
        ProgramFiscaliteSelect
    },

    data: () => ({
        options: [],
        sales: [],
        contacts: [],
        programs: [],
        places: [],
        productsCount: 0,
        programsCount: 0,
        allPrograms: [],

        contactsSearch: '',
        displayContactsSearch: false,
        optionSearch: '',
        displayOptionsSearch: false,
        saleSearch: '',
        displaySalesSearch: false,

        productSearch: {
            types: [],
            rooms: [],
            fiscalites: [],
            locations: [],
            budgetMax: ''
        },
        programQuickAccess: null,

        actualities: [],
        actualitiesPage: 0,
        actualityInterval: null

    }),

    methods: {
        prev() {
            this.actualitiesPage = this.actualitiesPage === 0 ? this.actualities.length - 1 : this.actualitiesPage - 1;
        },

        next() {
            this.actualitiesPage = this.actualitiesPage === this.actualities.length - 1 ? 0 : this.actualitiesPage + 1;
        },

        toggleContactsSearch() {
            if (this.displayContactsSearch) {
                this.gotoContactsSearch();
            } else {
                this.displayContactsSearch = true;
                this.$nextTick(() => {
                    this.$refs.contactsSearch.focus();
                });
            }
        },

        onActualityEnter() {
            clearInterval(this.actualityInterval);
        },

        onActualityLeave() {
            this.startActualityInterval();
        },

        startActualityInterval() {
            this.actualityInterval = setInterval(() => {
                this.actualitiesPage++;
                if (this.actualitiesPage > this.actualities.length) {
                    this.actualitiesPage = 0;
                }
            }, 15000);
        },

        closeContactSearch() {
            setTimeout(() => {
                this.displayContactsSearch = false;
            }, 500);
        },

        toggleOptionsSearch() {
            if (this.displayOptionsSearch) {
                this.gotoOptionsSearch();
            } else {
                this.displayOptionsSearch = true;
                this.$nextTick(() => {
                    this.$refs.optionsSearch.focus();
                });
            }

        },

        closeOptionsSearch() {
            setTimeout(() => {
                this.displayOptionsSearch = false;
            }, 500);
        },

        toggleSalesSearch() {
            if (this.displaySalesSearch) {
                this.gotoSalesSearch();
            } else {
                this.displaySalesSearch = true;
                this.$nextTick(() => {
                    this.$refs.salesSearch.focus();
                });
            }
        },

        closeSalesSearch() {
            setTimeout(() => {
                this.displaySalesSearch = false;
            }, 500);
        },

        gotoContactsSearch() {
            this.$router.push(`/contacts?quickSearch=${this.contactsSearch}`);
        },

        gotoOptionsSearch() {
            this.$router.push(`/options?quickSearch=${this.optionSearch}`);
        },

        gotoSalesSearch() {
            this.$router.push(`/reservations?quickSearch=${this.saleSearch}`);
        },

        gotoProductsSearch() {
            const query = {};

            if (this.productSearch.types && this.productSearch.types.length > 0) {
                query.types = this.productSearch.types.join(',');
            }
            if (this.productSearch.rooms && this.productSearch.rooms.length > 0) {
                query.rooms = this.productSearch.rooms.join(',');
            }
            if (this.productSearch.fiscalites && this.productSearch.fiscalites.length > 0) {
                query.fiscalites = this.productSearch.fiscalites.join(',');
            }
            if (this.productSearch.locations && this.productSearch.locations.length > 0) {
                query.locations = this.productSearch.locations.join(',');
            }
            if (this.productSearch.budgetMax) {
                query.budget = '0:' + this.productSearch.budgetMax;
            }

            this.$router.push({ path: '/lots', query });
        },

        async fetchPlaces() {
            try {
                const { places } = await this.repos.places.getPlaces();
                this.places = places;
            } catch (err) {
                console.error(err);
            }
        },

        async fetchDashboard() {
            try {
                this.setLoading(true);

                const query = {};
                const { sales, options, contacts, programs, actualities, programsCount, productsCount, err } = await this.repos.dashboard.getDashboard(query);
                if (err) {
                    throw new Error(err);
                } else {
                    this.sales = sales;
                    this.options = options;
                    this.contacts = contacts;
                    this.programs = programs;
                    this.actualities = actualities;
                    this.productsCount = productsCount;
                    this.programsCount = programsCount;
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du rechargement de la page',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async fetchPrograms() {
            try {
                const query = { include: 'photos', limit: 1000, archived: 0 };
                const { programs, err } = await this.repos.programs.getPrograms(query);
                if (err) {
                    throw new Error(err);
                } else {
                    this.allPrograms = programs;
                }
            } catch (err) {
                console.error(err);
            }
        },

        getProgramPhoto(program) {
            if (Array.isArray(program.photos) && program.photos.length > 0) {
                return program.photos[0].info.url;
            }
            return 'https://cdn.shopify.com/s/files/1/1661/5755/products/property-placeholder_grande.jpg?v=1482778691';
        }
    },

    watch: {
        programQuickAccess() {
            this.$router.push(`/programmes/${this.programQuickAccess}`);
        }
    },

    created() {
        this.fetchDashboard();
        this.$store.dispatch('products/fetchProductsSummary');
        this.fetchPlaces();
        this.fetchPrograms();
        this.startActualityInterval();
    }
};
</script>
